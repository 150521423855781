import * as React from 'react';

import Layout from '../components/core/layout';
import Seo from '../components/core/seo';
import A from '../components/core/a';
import Navigate from '../components/core/navigate';
import P from '../components/core/p';
import Span from '../components/core/span';
import H3 from '../components/core/h3';

import Profile from '../components/images/profile';
import Others from '../components/pages/about/social-section';
import Awards from '../components/pages/about/awards-section';

const AboutPage = () => (
  <Layout>
    <Seo title="About" />
    <Profile />
    <div className="py-4">
      <P>I currently live in California and work on my idea(s). I grew up in Turkey and doing MSCS at USF.</P>
    </div>
    <P>
      1. I like to work on problems where I can maximize f(x, y) = x * y, where x = target audience, y = similarity(cem,
      problem) * utility delta, and 0 ≤ similarity(a, b) ≤ 1.
    </P>
    <P>2. I like leverages + I like to be useful to the world = I'm developing software.</P>
    <P>3. I have an obsession with getting things done.</P>
    <P>4. I love learning, and compounding. I prefer textbooks rather than gradient descent.</P>
    <div className="py-4">
      <Span>ping me: ctuncelli%at%yahoo%dot%com</Span>
    </div>
    <div className="py-4">
      <H3>Areas of Interest</H3>
      <P>- Science [Math (Probabilistic Number Theory), Physics (Mechanics)]</P>
      <P>- Deep Tech</P>
      <P>- Economics</P>
    </div>
  </Layout>
);

export default AboutPage;
