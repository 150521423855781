import * as React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const Navigate = ({ href, text, additionalStyle }) => {
  return (
    <Link to={href} className={cn('text-blue-600', additionalStyle)}>
      {text}
    </Link>
  );
};

Navigate.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  additionalStyle: PropTypes.string,
};

export default Navigate;
